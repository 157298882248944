<template>
  <div>
    <TitleMore
      :title="['大事记']"
      :isMore="false"
    ></TitleMore>
    <div class="moneyCard">
      <div class="selectCard">
        <p
          class="selectCardItem"
          :class="[selectIndex==index?'activeSelect':'']"
          v-for="(item,index) in selectData"
          :key="index"
          @click="activeSelect(index,item.title)"
        >{{item.title}}</p>
      </div>
      <div class="donateFormContent">
        <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">{{ListData.dsj_bt}}</p>
        <TitleLine width="200px"></TitleLine>
        <div
          class="content"
          v-html="ListData.dsj_info"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      selectData: ["2020", "2019", "2018", "2017"],
      selectIndex: 0,
      ListData: [],
      year: '',
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getYears();
  },

  methods: {
    activeSelect(index, val) {
      this.selectIndex = index;
      this.year = val.slice(2);
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      apiUrls.selfDynamicList({ node: "dsj", search_EQ_p0: this.year }).then((res) => {
        this.ListData = res.results.data[0] || [];
      });
    },
    getYears() {
      apiUrls.selfDynamicList({ node: "dsj",size: '21' }).then((res) => {
        this.selectData = res.results.data.reverse();
        this.year = this.selectData[0].title.slice(2);
        this.getList();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.moneyCard {
  display: flex;
  width: 1200px;
  margin: 20px auto;

  justify-content: space-between;
  .selectCard {
    display: flex;
    flex-direction: column;
    width: 180px;
    // height: auto;
    min-height: 65px;
    box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
    .selectCardItem {
      width: 100%;
      height: 65px;
      line-height: 65px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      background-color: #ffffff;
      cursor: pointer;
    }
    .activeSelect {
      background-color: #d1292e;
      color: #ffffff;
    }
  }
  .donateFormContent {
    box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
    background-color: #ffffff;
    width: 990px;
    padding: 20px 0px;
    .content {
      width: calc(100% - 80px);
      padding: 20px 40px;
      line-height:30px;
    }
  }
}
</style>