<template>
  <p
    class="titleBotLine"
    style="width: 200px;"
  >
    <!-- <span class="titleBotCenterLine"></span> -->
  </p>
</template>

<script>
export default {
  data() {
    return {};
  },

  
  components: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.titleBotLine {
  position: relative;
  height: 20px;
  background: #dcdcdc;
  margin:0px auto;
  margin-bottom: 10px;
  background: url(../assets/images/分割.png) no-repeat;
  background-size: 100% 100%;
  /* .titleBotCenterLine {
    width: 70px;
    position: absolute;
    height: 6px;
    margin-left: -35px;
    left: 50%;
    top: -3px;
    background-color: #d1292e;
  } */
}
</style>